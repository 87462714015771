export enum Currency {
  Euro = "EUR",
  Krona = "SEK",
  Dollar = "USD",
}

export const locale = {
  [Currency.Euro]: {
    pressure1: 20,
    pressure2: 40,
    pressureUnit: "bar",
    waterUnit: "l",
    waterConsumption: "l/min",
    waterCost: "€/m³",
    chemicalsConsumption: "l/year",
    chemicalsCost: "€/l",
    cleanerCost: "€/hour",
    cost: "€",
    pressure1WaterConsumption: 30,
    pressure2WaterConsumption: 22,
  },
  [Currency.Krona]: {
    pressure1: 20,
    pressure2: 40,
    pressureUnit: "bar",
    waterUnit: "l",
    waterConsumption: "l/min",
    waterCost: "kr/m³",
    chemicalsConsumption: "l/year",
    chemicalsCost: "kr/l",
    cleanerCost: "kr/hour",
    cost: "kr",
    pressure1WaterConsumption: 30,
    pressure2WaterConsumption: 22,
  },
  [Currency.Dollar]: {
    pressure1: 325,
    pressure2: 580,
    pressureUnit: "psi",
    waterUnit: "gal",
    waterConsumption: "gal/min",
    waterCost: "$/1000gal",
    chemicalsConsumption: "gal/year",
    chemicalsCost: "$/gal",
    cleanerCost: "$/hour",
    cost: "$",
    pressure1WaterConsumption: 10,
    pressure2WaterConsumption: 7.33,
  },
};
