import { Button, Card, Steps } from "antd";
import React, { useEffect, useState } from "react";

import { locale, Currency } from "../../ProjectCreationPage/locale";
import CustomerService, { Customer } from "../../../services/customerService";
import { ProductionType } from "../../../services/projectService";
import ContextStep from "./ContextStep";
import CurrentWaterStep from "./CurrentWaterStep";
import CurrentChemicalsStep from "./CurrentChemicalsStep";
import CurrentLabourStep from "./CurrentLabourStep";
import SystemProposalStep from "./SystemProposalStep";
import SummaryStep from "./SummaryStep";

import "./Analyzer.scss";

const calculateSuggestedPressure = (currency: Currency, productionType: ProductionType): number | undefined => {
  switch (productionType) {
    case ProductionType.Meat:
      return locale[currency].pressure2;
    case ProductionType.Vegetarian:
      return locale[currency].pressure2;
    case ProductionType.Bakery:
      return locale[currency].pressure1;
    case ProductionType.Beverage:
      return locale[currency].pressure1;
    case ProductionType.PreparedFood:
      return locale[currency].pressure1;
    case ProductionType.Dairy:
      return locale[currency].pressure1;
    case ProductionType.Poultry:
      return locale[currency].pressure2;
    case ProductionType.Fish:
      return locale[currency].pressure2;
    case ProductionType.Other:
      return locale[currency].pressure1;
  }
};

interface Props {
  className?: string;
}

const Analyzer: React.FunctionComponent<Props> = (props) => {
  const [customers, setCustomers] = useState<Customer[]>([]);

  const [currentStep, setCurrentStep] = useState(0);

  const [customerId, setCustomerId] = useState<string>();
  const [currency, setCurrency] = useState<Currency>();
  const [productionType, setProductionType] = useState<ProductionType>();
  const [productionAmount, setProductionAmount] = useState<number>();
  const [productionUnit, setProductionUnit] = useState<string>();
  const [currentWaterPressure, setCurrentWaterPressure] = useState<number>();
  const [currentWaterConsumption, setCurrentWaterConsumption] = useState<number>();
  const [currentHeatedWaterCost, setCurrentHeaterWaterCost] = useState<number>();
  const [currentWasteWaterCost, setCurrentWasteWaterCost] = useState<number>();
  const [currentNumberOfChemicals, setCurrentNumberOfChemicals] = useState<number>();
  const [containersPurchasedToday, setContainersPurchasedToday] = useState(false);
  const [decentralDosing, setDecentralDosing] = useState(false);
  const [currentChemicals, setCurrentChemicals] = useState<any[]>([]);
  const [currentNumberOfCleaningSessions, setCurrentNumberOfCleaningSessions] = useState<number>();
  const [currentNumberOfCleaners, setCurrentNumberOfCleaners] = useState<number>();
  const [currentCleaningSessionDuration, setCurrentCleaningSessionDuration] = useState<number>();
  const [currentFlushingDuration, setCurrentFlushingDuration] = useState<number>();
  const [currentCleanerCost, setCurrentCleanerCost] = useState<number>();
  const [suggestedPressure, setSuggestedPressure] = useState<number>();
  const [timeSavingsWithCcs, setTimeSavingsWithCcs] = useState<number | undefined>(10);

  useEffect(() => {
    CustomerService.getCustomers()
      .then((customers) => {
        setCustomers(customers);
      })
      .catch(() => {
        setCustomers([]);
      });
  }, []);

  useEffect(() => {
    if (currency === Currency.Dollar) {
      setTimeSavingsWithCcs(20);
    } else {
      setTimeSavingsWithCcs(10);
    }
  }, [currency]);

  const changeToPreviousStep = () => {
    setCurrentStep((step) => step - 1);
  };

  const submitProjectStep = () => {
    if (currentStep === 0) {
      setSuggestedPressure(calculateSuggestedPressure(currency!, productionType!));
    }

    setCurrentStep((step) => step + 1);
  };

  const handleProductionTypeChange = (value?: ProductionType) => {
    setProductionType(value);
  };

  const handleCurrentNumberOfChemicalsChange = (value?: number) => {
    setCurrentNumberOfChemicals(value);
    setCurrentChemicals(
      [...Array(value).keys()].map(() => ({
        consumption: undefined,
        cost: undefined,
      }))
    );
  };

  const isPreviousDisabled = (): boolean => {
    return currentStep <= 0;
  };

  const isNextDisabled = (): boolean => {
    switch (currentStep) {
      case 0:
        return currency === undefined || productionType === undefined;
      case 1:
        return (
          currentWaterPressure === undefined ||
          currentWaterConsumption === undefined ||
          currentHeatedWaterCost === undefined ||
          currentWasteWaterCost === undefined
        );
      case 2:
        return !decentralDosing && currentNumberOfChemicals === undefined;
      case 3:
        return (
          currentNumberOfCleaningSessions === undefined ||
          currentNumberOfCleaners === undefined ||
          currentCleaningSessionDuration === undefined ||
          currentFlushingDuration === undefined ||
          currentCleanerCost === undefined
        );
      case 4:
        return suggestedPressure === undefined || timeSavingsWithCcs === undefined;
      default:
        return true;
    }
  };

  const titles = [
    "Analysis context",
    "Current water consumption",
    "Current chemicals consumption",
    "Current Labor consumption",
    "Lagafors system proposal",
    "Summary",
  ];

  const shortTitles = ["Start", "Water", "Chemicals", "Labor", "Proposal", "Summary"];

  const steps = [
    <ContextStep
      initial={{
        currency: currency,
        productionType: productionType,
        customer: customerId,
        productionAmount: productionAmount,
        productionUnit: productionUnit,
      }}
      onCustomerChange={setCustomerId}
      onCurrencyChange={setCurrency}
      onProductionTypeChange={handleProductionTypeChange}
      onProductionAmountChange={setProductionAmount}
      onProductionUnitChange={setProductionUnit}
      customers={customers}
    />,
    <CurrentWaterStep
      initial={{
        waterPressure: currentWaterPressure,
        waterConsumption: currentWaterConsumption,
        heatedWaterCost: currentHeatedWaterCost,
        wasteWaterCost: currentWasteWaterCost,
      }}
      currency={currency!}
      onWaterPressureChange={setCurrentWaterPressure}
      onWaterConsumptionChange={setCurrentWaterConsumption}
      onHeatedWaterCostChange={setCurrentHeaterWaterCost}
      onWasteWaterCostChange={setCurrentWasteWaterCost}
    />,
    <CurrentChemicalsStep
      initial={{
        decentralDosing: decentralDosing,
        numberOfChemicals: currentNumberOfChemicals,
        containersPurchasedToday: containersPurchasedToday,
        ...(() => {
          const x: any = {};

          for (let i = 0; i < (currentNumberOfChemicals || 0); i++) {
            x[`consumption${i}`] = currentChemicals[i].consumption;
            x[`cost${i}`] = currentChemicals[i].cost;
          }

          return x;
        })(),
      }}
      currency={currency!}
      onDecentralChange={setDecentralDosing}
      onNumberOfChemicalsChange={handleCurrentNumberOfChemicalsChange}
      onContainersPurchasedTodayChange={setContainersPurchasedToday}
      onChemicalConsumptionChange={(i, value) => {
        const x = currentChemicals;
        x[i].consumption = value;
        setCurrentChemicals(x);
      }}
      onChemicalCostChange={(i, value) => {
        const x = currentChemicals;
        x[i].cost = value;
        setCurrentChemicals(x);
      }}
    />,
    <CurrentLabourStep
      initial={{
        numberOfCleaningSessions: currentNumberOfCleaningSessions,
        numberOfCleaners: currentNumberOfCleaners,
        cleaningSessionDuration: currentCleaningSessionDuration,
        flushingDuration: currentFlushingDuration,
        cleanerCost: currentCleanerCost,
      }}
      currency={currency!}
      onNumberOfCleaningSessionsChange={setCurrentNumberOfCleaningSessions}
      onNumberOfCleanersChange={setCurrentNumberOfCleaners}
      onCleaningSessionDurationChange={setCurrentCleaningSessionDuration}
      onFlushingDurationChange={setCurrentFlushingDuration}
      onCleanerCostChange={setCurrentCleanerCost}
    />,
    <SystemProposalStep
      initial={{ suggestedPressure, timeSavingsWithCcs }}
      currency={currency!}
      productionType={productionType!}
      onSuggestedPressureChange={setSuggestedPressure}
      onTimeSavingsWithCcsChange={setTimeSavingsWithCcs}
    />,
    <SummaryStep
      customerId={customerId}
      currency={currency!}
      productionAmount={productionAmount!}
      productionUnit={productionUnit!}
      currentWaterPressure={currentWaterPressure!}
      suggestedWaterPressure={suggestedPressure!}
      numberOfCleaningSessions={currentNumberOfCleaningSessions!}
      numberOfCleaners={currentNumberOfCleaners!}
      cleaningSessionDuration={currentCleaningSessionDuration!}
      flushingDuration={currentFlushingDuration!}
      currentWaterConsumption={currentWaterConsumption!}
      heatedWaterCost={currentHeatedWaterCost!}
      wasteWaterCost={currentWasteWaterCost!}
      timeSavingsWithCcs={timeSavingsWithCcs!}
      decentralDosing={decentralDosing}
      chemicals={currentChemicals}
      containersPurchasedToday={containersPurchasedToday}
      cleanerCost={currentCleanerCost!}
    />,
  ];

  return (
    <div className={props.className ? `analyzer ${props.className}` : "analyzer"}>
      <Steps
        className="steps"
        current={currentStep}
        labelPlacement="vertical"
        onChange={(c) => {
          setCurrentStep(c);
        }}
      >
        {[...Array(6).keys()].map((i) => (
          <Steps.Step key={i} title={shortTitles[i]} />
        ))}
      </Steps>
      <Card
        className="step"
        title={titles[currentStep]}
        extra={
          <div className="step-extra">
            <Button disabled={isPreviousDisabled()} onClick={changeToPreviousStep}>
              Previous
            </Button>
            <Button disabled={isNextDisabled()} onClick={submitProjectStep}>
              Next
            </Button>
          </div>
        }
      >
        {steps[currentStep]}
      </Card>
    </div>
  );
};

export default Analyzer;
