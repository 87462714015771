import React, { useState } from "react";
import { Checkbox, Collapse, Form, InputNumber } from "antd";
import { formatNumber, parseNumber } from "utils/formatterParser";
import { locale, Currency } from "../locale";

import "./CurrentChemicalsStep.scss";
import { Chemical } from "../../../services/projectService";

interface Props {
  initial: any;
  currency: Currency;
  onNumberOfChemicalsChange: (value?: number) => void;
  onContainersPurchasedTodayChange: (value: boolean) => void;
  onChemicalConsumptionChange: (id: number, value?: number) => void;
  onChemicalCostChange: (id: number, value?: number) => void;
  currentChemicals: Chemical[];
}

const MIN_NUMBER_OF_CHEMICALS = 1;
const MAX_NUMBER_OF_CHEMICALS = 5;
const MIN_CONSUMPTION = 0;
const MIN_COST = 0;
const BOX_WIDTH = "45px";

const CurrentChemicalsStep: React.FunctionComponent<Props> = (props) => {
  const [form] = Form.useForm();

  const [numberOfChemicals, setNumberOfChemicals] = useState<number | undefined>(
    props.currentChemicals.length === 0 ? undefined : props.currentChemicals.length
  );

  const handleNumberOfChemicalsChange = (value: number | null) => {
    const newValue = value === null ? undefined : value;
    setNumberOfChemicals(newValue);
    props.onNumberOfChemicalsChange(newValue);

    if (newValue !== undefined) {
      let updatedValues = {};
      for (let i = 0; i < newValue; i++) {
        const consumptionKey = `consumption${i}`;
        const costKey = `cost${i}`;
        updatedValues = {
          ...updatedValues,
          [consumptionKey]: props.currentChemicals[i]?.consumption,
          [costKey]: props.currentChemicals[i]?.cost,
        };
      }

      for (let i = newValue; i < props.currentChemicals.length; i++) {
        const consumptionKey = `consumption${i}`;
        const costKey = `cost${i}`;
        updatedValues = {
          ...updatedValues,
          [consumptionKey]: undefined,
          [costKey]: undefined,
        };
      }

      form.setFieldsValue(updatedValues);
    }
  };

  return (
    <Form
      initialValues={props.initial}
      className="current-chemicals-step"
      form={form}
      labelWrap
      labelCol={{ offset: 4, span: 6 }}
      wrapperCol={{ span: 8 }}
      requiredMark="optional"
      colon={false}
    >
      <Form.Item name="numberOfChemicals" label="Number of chemicals" rules={[{ required: true, message: <></> }]}>
        <InputNumber
          min={MIN_NUMBER_OF_CHEMICALS}
          max={MAX_NUMBER_OF_CHEMICALS}
          onChange={handleNumberOfChemicalsChange}
          formatter={formatNumber}
          parser={parseNumber}
        />
      </Form.Item>
      <Form.Item
        name="containersPurchasedToday"
        label="IBC purchased today"
        rules={[{ required: true, message: <></> }]}
      >
        <Checkbox
          checked={props.initial.containersPurchasedToday}
          onChange={(e) => props.onContainersPurchasedTodayChange(e.target.checked)}
        />
      </Form.Item>
      <div className="chemicals">
        {numberOfChemicals &&
          props.currentChemicals.map((chem, i) => (
            <Collapse className="chemical" key={i} defaultActiveKey={[...Array(props.currentChemicals.length).keys()]}>
              <Collapse.Panel key={i} header={`Chemical ${i + 1}`}>
                <Form.Item
                  name={`consumption${i}`}
                  label="Consumption"
                  rules={[{ required: true, message: <></> }]}
                  labelCol={{ offset: 2, span: 8 }}
                  wrapperCol={{ span: 10 }}
                >
                  <InputNumber
                    value={chem.consumption ?? 0}
                    min={MIN_CONSUMPTION}
                    addonAfter={
                      <span style={{ width: BOX_WIDTH, display: "inline-block" }}>
                        {locale[props.currency].chemicalsConsumption}
                      </span>
                    }
                    onChange={(value) => props.onChemicalConsumptionChange(i, value === null ? undefined : value)}
                    formatter={formatNumber}
                    parser={parseNumber}
                  />
                </Form.Item>
                <Form.Item
                  name={`cost${i}`}
                  label="Cost"
                  rules={[{ required: true, message: <></> }]}
                  labelCol={{ offset: 2, span: 8 }}
                  wrapperCol={{ span: 10 }}
                >
                  <InputNumber
                    min={MIN_COST}
                    value={chem.cost}
                    addonAfter={
                      <span style={{ width: BOX_WIDTH, display: "inline-block" }}>
                        {locale[props.currency].chemicalsCost}
                      </span>
                    }
                    onChange={(value) => props.onChemicalCostChange(i, value === null ? undefined : value)}
                    decimalSeparator=","
                    formatter={formatNumber}
                    parser={parseNumber}
                  />
                </Form.Item>
              </Collapse.Panel>
            </Collapse>
          ))}
      </div>
    </Form>
  );
};

export default CurrentChemicalsStep;
