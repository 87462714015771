import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, InputNumber, Select } from "antd";
import { formatNumber, parseNumber } from "utils/formatterParser";
import { ProductionType, ProjectType } from "../../../services/projectService";
import { Customer } from "../../../services/customerService";
import { Currency } from "../locale";

const BOX_WIDTH = "100px";

interface Props {
  initial: {
    projectName?: string;
    projectType?: ProjectType;
    customer?: string;
    productionType?: ProductionType;
    currency?: Currency;
    productionAmount?: number;
    productionUnit?: string;
  };
  customers: Customer[];
  currency?: Currency;
  onCustomerChange: (value: string) => void;
  onProductionTypeChange: (value: ProductionType) => void;
  onProjectNameChange: (value: string) => void;
  onProjectTypeChange: (value: ProjectType) => void;
  onCurrencyChange: (value: Currency) => void;
  onProductionAmountChange: (value?: number) => void;
  onProductionUnitChange: (value?: string) => void;
}

function extractCity(address: string) {
  if (!address) {
    return "";
  }

  const parts = address.trim().split(/\s+/);

  let index = -1;
  for (let i = 0; i < parts.length; i++) {
    if (/\d/.test(parts[i])) {
      index = i;
    }
  }

  const cityParts = parts.slice(index + 1);

  return cityParts.join(" ");
}

const BaseInfoStep: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    if (form.getFieldValue("units") !== props.currency) {
      form.setFieldValue("units", props.currency);
    }
  }, [form, props.currency]);

  const productionTypes = [
    {
      name: t("project.productionType.meat"),
      value: ProductionType.Meat,
    },
    {
      name: "Vegetarian",
      value: ProductionType.Vegetarian,
    },
    {
      name: t("project.productionType.bakery"),
      value: ProductionType.Bakery,
    },
    {
      name: t("project.productionType.beverage"),
      value: ProductionType.Beverage,
    },
    {
      name: t("project.productionType.preparedFood"),
      value: ProductionType.PreparedFood,
    },
    {
      name: t("project.productionType.dairy"),
      value: ProductionType.Dairy,
    },
    {
      name: t("project.productionType.poultry"),
      value: ProductionType.Poultry,
    },
    {
      name: t("project.productionType.fish"),
      value: ProductionType.Fish,
    },
    {
      name: t("project.productionType.other"),
      value: ProductionType.Other,
    },
  ];

  const currencies = [
    { name: "Euro / Metric", value: Currency.Euro },
    { name: "Swedish Krona / Metric", value: Currency.Krona },
    { name: "US Dollar / Imperial", value: Currency.Dollar },
  ];

  const projectTypes = [
    {
      name: t("project.projectType.offer"),
      value: ProjectType.Offer,
    },
    {
      name: t("project.projectType.offerWithRoi"),
      value: ProjectType.OfferWithRoi,
    },
  ];

  const handleCustomerChange = (customerId: Customer["id"]) => {
    // See if the customer has an assigned currency, if so then set it
    const customer = props.customers.find((c) => c.id === customerId);
    const customerCurrency = customer?.currency;
    switch (customerCurrency) {
      case "EUR":
        handleCurrencyChange(Currency.Euro);
        break;
      case "USD":
        handleCurrencyChange(Currency.Dollar);
        break;
      case "SEK":
        handleCurrencyChange(Currency.Krona);
        break;
      default:
        handleCurrencyChange(Currency.Krona);
        break;
    }

    props.onCustomerChange(customerId);
  };

  const handleCurrencyChange = (value: Currency) => {
    if (props.currency === value) return;
    props.onCurrencyChange(value);
    form.setFieldValue("units", value);
  };

  return (
    <Form
      initialValues={props.initial}
      form={form}
      labelWrap
      labelCol={{ offset: 4, span: 6 }}
      wrapperCol={{ span: 8 }}
      requiredMark="optional"
      colon={false}
    >
      <Form.Item name="customer" label="Customer" rules={[{ required: true, message: <></> }]}>
        <Select
          loading={props.customers.length === 0}
          onChange={handleCustomerChange}
          showSearch
          optionLabelProp="label"
          filterOption={(input, option) => {
            if (!option) {
              return false;
            }
            const customerName = (option as any).customerName || "";
            const customerCity = (option as any).customerCity || "";
            const inputValue = input.toLowerCase();
            return customerName.toLowerCase().includes(inputValue) || customerCity.toLowerCase().includes(inputValue);
          }}
        >
          {props.customers
            .filter((customer) => customer.status === "OK")
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((customer) => {
              const cityName = extractCity(customer.city);
              return (
                <Select.Option
                  key={customer.id}
                  value={customer.id}
                  label={customer.name}
                  customerName={customer.name}
                  customerCity={cityName}
                >
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>{customer.name}</span>
                    <span style={{ color: "#7c7c7c" }}>{cityName}</span>
                  </div>
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
      <Form.Item name="projectName" label="Project name" rules={[{ required: true, message: <></> }]}>
        <Input onChange={(e) => props.onProjectNameChange(e.target.value)} />
      </Form.Item>
      <Form.Item name="productionType" label="Production type" rules={[{ required: true, message: <></> }]}>
        <Select onChange={(value) => props.onProductionTypeChange(value)}>
          {productionTypes.map((productionType) => (
            <Select.Option key={productionType.value} value={productionType.value}>
              {productionType.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="units" label="Units" rules={[{ required: true, message: <></> }]}>
        <Select
          onChange={handleCurrencyChange}
          options={currencies.map((unit) => ({ label: unit.name, value: unit.value }))}
        />
      </Form.Item>
      <Form.Item name="projectType" label="Project type" rules={[{ required: true, message: <></> }]}>
        <Select onChange={props.onProjectTypeChange}>
          {projectTypes.map((projectType) => (
            <Select.Option key={projectType.value} value={projectType.value}>
              {projectType.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {props.initial.projectType === ProjectType.OfferWithRoi && (
        <Form.Item label="Annual production" required tooltip="The total annual production">
          <div style={{ display: "flex", alignItems: "center" }}>
            <Form.Item
              name="productionAmount"
              noStyle
              rules={[{ required: true, message: "Please input the annual production!" }]}
            >
              <InputNumber
                onChange={(value) => props.onProductionAmountChange(value === null ? undefined : Number(value))}
                decimalSeparator=","
                formatter={formatNumber}
                parser={parseNumber}
                style={{ marginRight: 8 }}
              />
            </Form.Item>
            <Form.Item name="productionUnit" noStyle rules={[{ required: true, message: "Please input the unit!" }]}>
              <Input
                onChange={(e) => props.onProductionUnitChange(e.target.value)}
                placeholder="unit"
                style={{ width: BOX_WIDTH }}
              />
            </Form.Item>
          </div>
        </Form.Item>
      )}
    </Form>
  );
};

export default BaseInfoStep;
