import React from "react";
import { Form, InputNumber, Select } from "antd";
import { formatNumber, parseNumber } from "utils/formatterParser";
import { locale, Currency } from "../locale";

const MIN_WATER_CONSUMPTION = 0;
const MIN_HEATED_WATER_COST = 0;
const MIN_WASTE_WATER_COST = 0;
const BOX_WIDTH = "65px";

interface Props {
  initial: { waterPressure?: number; waterConsumption?: number; heatedWaterCost?: number; wasteWaterCost?: number };
  currency: Currency;
  onWaterPressureChange: (value?: number) => void;
  onWaterConsumptionChange: (value?: number) => void;
  onHeatedWaterCostChange: (value?: number) => void;
  onWasteWaterCostChange: (value?: number) => void;
}

const CurrentWaterStep: React.FunctionComponent<Props> = (props) => {
  const waterPressures = [
    {
      name: (props.currency !== "USD" ? "5 " : "75 ") + locale[props.currency].pressureUnit,
      value: 5,
    },
    {
      name: (props.currency !== "USD" ? "10 " : "150 ") + locale[props.currency].pressureUnit,
      value: 10,
    },
    {
      name: (props.currency !== "USD" ? "20 " : "325 ") + locale[props.currency].pressureUnit,
      value: 20,
    },
    {
      name: (props.currency !== "USD" ? "40 " : "580 ") + locale[props.currency].pressureUnit,
      value: 40,
    },
  ];

  return (
    <Form
      initialValues={props.initial}
      labelWrap
      labelCol={{ offset: 4, span: 6 }}
      wrapperCol={{ span: 8 }}
      requiredMark="optional"
      colon={false}
    >
      <Form.Item
        name="waterPressure"
        label="Water pressure"
        rules={[{ required: true, message: <></> }]}
        tooltip={<div>What is the water pressure used in today's cleaning process?</div>}
      >
        <Select
          onChange={(value) => props.onWaterPressureChange(value === null ? undefined : value)}
          style={{ width: "100%", display: "flex", justifyContent: "space-between" }}
        >
          {waterPressures.map((waterPressure) => (
            <Select.Option key={waterPressure.value} value={waterPressure.value}>
              {waterPressure.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="waterConsumption"
        label="Water flow/drop point"
        rules={[{ required: true, message: <></> }]}
        tooltip={<div>Flow from hose/nozzle/spray gun or estimated average</div>}
      >
        <InputNumber
          min={MIN_WATER_CONSUMPTION}
          addonAfter={
            <span style={{ width: BOX_WIDTH, display: "inline-block" }}>{locale[props.currency].waterConsumption}</span>
          }
          onChange={(value) => props.onWaterConsumptionChange(value === null ? undefined : value)}
          decimalSeparator=","
          formatter={formatNumber}
          parser={parseNumber}
        />
      </Form.Item>
      <Form.Item
        name="heatedWaterCost"
        label="Heated water cost"
        rules={[{ required: true, message: <></> }]}
        tooltip={
          <div>
            Purchase price of water including heating. {props.currency === "EUR" && "Industrial average 4€"}
            {props.currency === "SEK" && "Industrial average 40 SEK"}
            {props.currency === "USD" && "Industrial average $8"}
          </div>
        }
      >
        <InputNumber
          min={MIN_HEATED_WATER_COST}
          addonAfter={
            <span style={{ width: BOX_WIDTH, display: "inline-block" }}>{locale[props.currency].waterCost}</span>
          }
          onChange={(value) => props.onHeatedWaterCostChange(value === null ? undefined : value)}
          decimalSeparator=","
          formatter={formatNumber}
          parser={parseNumber}
        />
      </Form.Item>
      <Form.Item
        name="wasteWaterCost"
        label="Waste water cost"
        rules={[{ required: true, message: <></> }]}
        tooltip={
          <div>
            Municipal sewerage cost including own treatment process.{" "}
            {props.currency === "SEK" && " Industrial average 20 SEK"}
            {props.currency === "USD" && " Industrial average $8"}
            {props.currency === "EUR" && " Industrial average 2€"}
          </div>
        }
      >
        <InputNumber
          min={MIN_WASTE_WATER_COST}
          addonAfter={
            <span style={{ width: BOX_WIDTH, display: "inline-block" }}>{locale[props.currency].waterCost}</span>
          }
          onChange={(value) => props.onWasteWaterCostChange(value === null ? undefined : value)}
          decimalSeparator=","
          formatter={formatNumber}
          parser={parseNumber}
        />
      </Form.Item>
    </Form>
  );
};

export default CurrentWaterStep;
