import React, { useMemo, useState } from "react";
import { Table, Tooltip, Button, Radio, Modal, Form, Input } from "antd";
import type { ColumnsType } from "antd/es/table";

import { locale, Currency } from "../../../ProjectCreationPage/locale";
import { FilePdfOutlined } from "@ant-design/icons";

import "./SummaryStep.scss";
import { EmailContent } from "../../../../services/projectService";
import CsaService from "../../../../services/csaService";
import { Locale } from "../../../../locales/types";

const TIME_SAVINGS_WITH_20_BAR = 10; // 10% time savings with 20 bar compared to lower pressures

interface Props {
  customerId?: string;
  currency: Currency;
  productionAmount: number;
  productionUnit: string;
  currentWaterPressure: number;
  suggestedWaterPressure: number;
  numberOfCleaningSessions: number;
  numberOfCleaners: number;
  cleaningSessionDuration: number;
  flushingDuration: number;
  currentWaterConsumption: number;
  heatedWaterCost: number;
  wasteWaterCost: number;
  timeSavingsWithCcs: number;
  decentralDosing: boolean;
  chemicals: any[];
  containersPurchasedToday: boolean;
  cleanerCost: number;

  hideFooter?: boolean;
}

interface DataType {
  key: React.Key;
  group: string;
  current: string;
  lagafors: string;
  savings: string;
  skuSavingsWater?: string;
}

const { TextArea } = Input;

const SummaryStep: React.FunctionComponent<Props> = (props) => {
  const numFormatOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  };

  const [pdfLang, setPdfLang] = useState<Locale>(Locale.English);
  const currentFlushingDuration = useMemo(() => {
    return props.numberOfCleaningSessions * props.numberOfCleaners * props.flushingDuration;
  }, [props.numberOfCleaningSessions, props.numberOfCleaners, props.flushingDuration]);

  const currentCleaningDuration = useMemo(() => {
    return props.numberOfCleaningSessions * props.numberOfCleaners * props.cleaningSessionDuration;
  }, [props.numberOfCleaningSessions, props.numberOfCleaners, props.cleaningSessionDuration]);

  const currentWaterConsumption = useMemo(() => {
    return props.currentWaterConsumption * 0.001 * 60 * currentFlushingDuration;
  }, [currentFlushingDuration, props.currentWaterConsumption]);

  const currentWaterCost = useMemo(() => {
    return currentWaterConsumption * (props.heatedWaterCost + props.wasteWaterCost);
  }, [currentWaterConsumption, props.heatedWaterCost, props.wasteWaterCost]);

  const lagaforsCleaningDuration = useMemo(() => {
    return (
      props.numberOfCleaningSessions *
      props.numberOfCleaners *
      (props.cleaningSessionDuration * (1 - props.timeSavingsWithCcs / 100))
    );
  }, [props.numberOfCleaningSessions, props.numberOfCleaners, props.cleaningSessionDuration, props.timeSavingsWithCcs]);

  const lagaforsFlushingDuration = useMemo(() => {
    const timeSavingsWithCcs = props.currentWaterPressure < 20 ? 1 - TIME_SAVINGS_WITH_20_BAR / 100 : 1;
    return props.numberOfCleaningSessions * props.numberOfCleaners * props.flushingDuration * timeSavingsWithCcs;
  }, [props.currentWaterPressure, props.numberOfCleaningSessions, props.numberOfCleaners, props.flushingDuration]);

  const lagaforsWaterConsumption = useMemo(() => {
    let waterConsumption = 0;

    switch (props.suggestedWaterPressure) {
      case locale[props.currency].pressure1:
        waterConsumption = locale[props.currency].pressure1WaterConsumption * 0.001;
        break;
      case locale[props.currency].pressure2:
        waterConsumption = locale[props.currency].pressure2WaterConsumption * 0.001;
        break;
    }

    return waterConsumption * 0.001 * 60 * lagaforsFlushingDuration;
  }, [lagaforsFlushingDuration, props.currency, props.suggestedWaterPressure]);

  const lagaforsWaterCost = useMemo(() => {
    let waterConsumption = 0;

    switch (props.suggestedWaterPressure) {
      case locale[props.currency].pressure1:
        waterConsumption = locale[props.currency].pressure1WaterConsumption * 0.001;
        break;
      case locale[props.currency].pressure2:
        waterConsumption = locale[props.currency].pressure2WaterConsumption * 0.001;
        break;
    }

    return waterConsumption * 60 * lagaforsFlushingDuration * (props.heatedWaterCost + props.wasteWaterCost);
  }, [
    lagaforsFlushingDuration,
    props.suggestedWaterPressure,
    props.currency,
    props.heatedWaterCost,
    props.wasteWaterCost,
  ]);

  const waterSavings = useMemo(() => {
    return currentWaterCost - lagaforsWaterCost;
  }, [currentWaterCost, lagaforsWaterCost]);

  const waterSavingsPercentage = useMemo(() => {
    return (1 - lagaforsWaterCost / currentWaterCost) * 100;
  }, [currentWaterCost, lagaforsWaterCost]);

  const currentChemicalsCost = useMemo(() => {
    let cost = 0;

    props.chemicals.forEach((chemical) => {
      cost += chemical.consumption * chemical.cost;
    });

    return cost;
  }, [props.chemicals]);

  const lagaforsChemicalsCost = useMemo(() => {
    let cost = 0;

    let chemicalsSavings = props.decentralDosing ? 1 : 0.75;

    props.chemicals.forEach((chemical) => {
      cost += chemical.consumption * chemicalsSavings * chemical.cost;
    });

    if (!props.containersPurchasedToday) {
      cost *= 0.9;
    }

    return cost;
  }, [props.chemicals, props.decentralDosing, props.containersPurchasedToday]);

  const chemicalsSavings = useMemo(() => {
    return currentChemicalsCost - lagaforsChemicalsCost;
  }, [currentChemicalsCost, lagaforsChemicalsCost]);

  const chemicalsSavingsPercentage = useMemo(() => {
    return (1 - lagaforsChemicalsCost / currentChemicalsCost) * 100;
  }, [currentChemicalsCost, lagaforsChemicalsCost]);

  const chemicalsSavingsSku = useMemo(() => {
    return chemicalsSavings / props.productionAmount;
  }, [chemicalsSavings, props.productionAmount]);

  const currentLabourCost = useMemo(() => {
    return currentCleaningDuration * props.cleanerCost;
  }, [currentCleaningDuration, props.cleanerCost]);

  const lagaforsLabourCost = useMemo(() => {
    return lagaforsCleaningDuration * props.cleanerCost;
  }, [lagaforsCleaningDuration, props.cleanerCost]);

  const labourSavings = useMemo(() => {
    return currentLabourCost - lagaforsLabourCost;
  }, [currentLabourCost, lagaforsLabourCost]);

  const labourSavingsSku = useMemo(() => {
    return labourSavings / props.productionAmount;
  }, [labourSavings, props.productionAmount]);

  const labourSavingsPercentage = useMemo(() => {
    return (1 - lagaforsLabourCost / currentLabourCost) * 100;
  }, [currentLabourCost, lagaforsLabourCost]);

  const currentTotalCost = useMemo(() => {
    return currentWaterCost + currentChemicalsCost + currentLabourCost;
  }, [currentWaterCost, currentChemicalsCost, currentLabourCost]);

  const lagaforsTotalCost = useMemo(() => {
    return lagaforsWaterCost + lagaforsChemicalsCost + lagaforsLabourCost;
  }, [lagaforsWaterCost, lagaforsChemicalsCost, lagaforsLabourCost]);

  const totalSavings = useMemo(() => {
    return currentTotalCost - lagaforsTotalCost;
  }, [currentTotalCost, lagaforsTotalCost]);

  const skuSavings = useMemo(() => {
    return (currentWaterCost - lagaforsWaterCost) / props.productionAmount;
  }, [currentWaterCost, lagaforsWaterCost, props.productionAmount]);

  const skuSavingsWater = useMemo(() => {
    return (currentWaterConsumption - lagaforsWaterConsumption) / props.productionAmount;
  }, [currentWaterConsumption, lagaforsWaterConsumption, props.productionAmount]);

  const totalSavingsPercentage = useMemo(() => {
    return (1 - lagaforsTotalCost / currentTotalCost) * 100;
  }, [currentTotalCost, lagaforsTotalCost]);

  const totalSavingsSku = useMemo(() => {
    return totalSavings / props.productionAmount;
  }, [totalSavings, props.productionAmount]);

  const convertBarToPsi = (barValue: number): number => {
    switch (barValue) {
      case 5:
        return 75;
      case 10:
        return 150;
      case 20:
        return 325;
      case 40:
        return 580;
      default:
        return barValue;
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "",
      dataIndex: "group",
      align: "center",
    },
    {
      title: "Current solution",
      align: "center",
      children: [
        {
          title: `${
            props.currency === "USD" ? convertBarToPsi(props.currentWaterPressure) : props.currentWaterPressure
          } ${props.currency === "USD" ? "psi" : locale[props.currency].pressureUnit}`,
          dataIndex: "current",
          align: "center",
        },
      ],
    },
    {
      title: "Lagafors system",
      align: "center",
      children: [
        {
          title: `${props.suggestedWaterPressure} ${locale[props.currency].pressureUnit}`,
          dataIndex: "lagafors",
          align: "center",
        },
        {
          title: "Savings per year",
          align: "center",
          children: [
            {
              title: "Total",
              dataIndex: "savings",
              align: "center",
            },
            {
              title: `Savings / ${props.productionUnit ? props.productionUnit : "unit"}`,
              dataIndex: "skuSavingsWater",
              align: "center",
              render: (savingsValue) => (
                <Tooltip placement="topLeft" title={`This value represents the estimated savings per unit.`}>
                  {savingsValue}
                </Tooltip>
              ),
            },
          ],
        },
      ],
    },
  ];

  const data: DataType[] = [
    {
      key: "water",
      group: "Water",
      current: `${currentWaterCost.toLocaleString("sv", numFormatOptions)} ${locale[props.currency].cost}`,
      lagafors: `${lagaforsWaterCost.toLocaleString("sv", numFormatOptions)} ${locale[props.currency].cost}`,
      savings: `${waterSavings.toLocaleString("sv", numFormatOptions)} ${
        locale[props.currency].cost
      } / ${waterSavingsPercentage.toPrecision(2)}%`,
      skuSavingsWater: `${isFinite(skuSavings) ? skuSavings.toFixed(2) : "?"} ${locale[props.currency].cost} / ${
        isFinite(skuSavingsWater) ? skuSavingsWater.toFixed(2) : "?"
      } ${locale[props.currency].waterUnit}`,
    },
    {
      key: "chemicals",
      group: "Chemicals",
      current: `${currentChemicalsCost.toLocaleString("sv", numFormatOptions)} ${locale[props.currency].cost}`,
      lagafors: `${lagaforsChemicalsCost.toLocaleString("sv", numFormatOptions)} ${locale[props.currency].cost}`,
      savings: `${chemicalsSavings.toLocaleString("sv", numFormatOptions)} ${
        locale[props.currency].cost
      } / ${chemicalsSavingsPercentage.toPrecision(2)}%`,
      skuSavingsWater: `${isFinite(chemicalsSavingsSku) ? chemicalsSavingsSku.toFixed(2) : "?"} ${
        locale[props.currency].cost
      }`,
    },
    {
      key: "labor",
      group: "Labor",
      current: `${currentLabourCost.toLocaleString("sv", numFormatOptions)} ${locale[props.currency].cost}`,
      lagafors: `${lagaforsLabourCost.toLocaleString("sv", numFormatOptions)} ${locale[props.currency].cost}`,
      savings: `${labourSavings.toLocaleString("sv", numFormatOptions)} ${
        locale[props.currency].cost
      } / ${labourSavingsPercentage.toPrecision(2)}%`,
      skuSavingsWater: `${isFinite(labourSavingsSku) ? labourSavingsSku.toFixed(2) : "?"} ${
        locale[props.currency].cost
      }`,
    },
    {
      key: "total",
      group: "Total",
      current: `${currentTotalCost.toLocaleString("sv", numFormatOptions)} ${locale[props.currency].cost}`,
      lagafors: `${lagaforsTotalCost.toLocaleString("sv", numFormatOptions)} ${locale[props.currency].cost}`,
      savings: `${totalSavings.toLocaleString("sv", numFormatOptions)}${
        locale[props.currency].cost
      } / ${totalSavingsPercentage.toPrecision(2)}%`,
      skuSavingsWater: `${isFinite(totalSavingsSku) ? totalSavingsSku.toFixed(2) : "?"} ${locale[props.currency].cost}`,
    },
  ];

  const onFinish = (values: EmailContent) => {
    let requestData = CsaService.sendEmail({ currentSituationAnalyzerData: props, email: values }, pdfLang);
    setModalOpen(false);
  };

  // Shown by default, only hidden if `hideFooter` is explicitly set
  const showFooter = [false, undefined].includes(props.hideFooter);
  const [generatingPdf, setGeneratingPdf] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="ant-card-body">
      <Table bordered pagination={false} columns={columns} dataSource={data} />
      {showFooter && (
        <div className="pdf-footer">
          {/* TODO: Break out into component */}
          <Button
            icon={<FilePdfOutlined />}
            className="pdf-button"
            disabled={generatingPdf}
            onClick={() => setModalOpen(true)}
          >
            PDF
          </Button>

          <Modal
            title="Create PDF"
            centered
            open={modalOpen}
            onOk={() => setModalOpen(false)}
            onCancel={() => setModalOpen(false)}
            footer={null}
          >
            <div className="pdf-modal">
              {/* <p>Here you can download the generated offer in different languages.</p> */}
              {/* <p>Here you can download the generated offer or you can send it to the customer as a PDF.</p> */}
              <h3>Select language</h3>
              <Radio.Group
                value={pdfLang}
                onChange={(newLang) => setPdfLang(newLang.target.value)}
                optionType="button"
                buttonStyle="solid"
                options={[
                  { label: "Svenska", value: Locale.Swedish },
                  { label: "English", value: Locale.English },
                  { label: "Deutsch", value: Locale.Deutsch },
                ]}
              />
              <h3>Download PDF</h3>
              <Button
                icon={<FilePdfOutlined />}
                className="pdf-button"
                disabled={generatingPdf}
                loading={generatingPdf ? { delay: 0 } : false}
                onClick={async () => {
                  try {
                    setGeneratingPdf(true);

                    let blob = await CsaService.generatePdf({ ...props }, pdfLang);
                    blob = new Blob([blob], { type: "application/pdf" }); // Recreating the blob and explicitly setting MIME-type apparently improves compatibility with some browsers

                    const objectUrl = URL.createObjectURL(blob);
                    const link = document.createElement("a");

                    // Emulate a clicked link to allow the file to be downloaded
                    link.href = objectUrl;
                    link.target = "_blank";
                    link.download = (props.customerId ?? "current_situation") + ".pdf";
                    link.click();

                    // For Firefox it is necessary to delay revoking the ObjectURL
                    setTimeout(function () {
                      URL.revokeObjectURL(objectUrl);
                    }, 100);
                  } catch (error) {
                    console.warn("error fetching project PDF", error);
                  } finally {
                    setGeneratingPdf(false);
                  }
                }}
              >
                Download
              </Button>
              <Button
                icon={<FilePdfOutlined />}
                className="pdf-button"
                disabled={generatingPdf}
                loading={generatingPdf ? { delay: 0 } : false}
                onClick={async () => {
                  try {
                    setGeneratingPdf(true);

                    let blob = await CsaService.generatePdf({ ...props }, pdfLang);
                    blob = new Blob([blob], { type: "application/pdf" });

                    const objectUrl = URL.createObjectURL(blob);

                    window.open(objectUrl, "_blank");

                    // Delay revoking the ObjectURL for Firefox compatibility
                    setTimeout(function () {
                      URL.revokeObjectURL(objectUrl);
                    }, 100);
                  } catch (error) {
                    console.warn("error fetching project PDF", error);
                  } finally {
                    setGeneratingPdf(false);
                  }
                }}
              >
                Open
              </Button>
              <h3 id="modal-header">Send PDF</h3>
              <Form onFinish={onFinish}>
                <Form.Item
                  name="address"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                      max: 255,
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                      max: 100,
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                  name="subject"
                  rules={[
                    {
                      required: true,
                      message: "Please input your subject!",
                      max: 1000,
                    },
                  ]}
                >
                  <Input placeholder="Subject" />
                </Form.Item>
                <Form.Item
                  name="text"
                  rules={[
                    {
                      required: true,
                      message: "Please input your message!",
                    },
                  ]}
                >
                  <TextArea rows={4} placeholder="Message" />
                </Form.Item>
                <Form.Item>
                  <div style={{ justifyContent: "end", display: "flex" }}>
                    <Button className="send-email-button" type="primary" htmlType="submit">
                      Send
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default SummaryStep;
