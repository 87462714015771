import React, { useEffect, useState } from "react";
import { Form, Button, Input, InputNumber, List, Select, Switch, Modal, Radio, Alert } from "antd";
import { FilePdfOutlined, SettingOutlined, CheckOutlined } from "@ant-design/icons";

import "./Summary.scss";
import ProjectsService, { EmailContent } from "../../../services/projectService";
import CsaSummary from "../../AnalyzerPage/Analyzer/SummaryStep";
import { Lts, Project, ProjectProduct } from "../../../services/projectService";
import { Currency } from "../../ProjectCreationPage/locale";
import { useTranslation } from "react-i18next";
import { Locale } from "../../../locales/types";

// NOTE: Changes to this must be reflected in the backend as well (internal/domain/project/common.go)
const SekPerEuro = 10;
const SekPerDollar = 9.5;
const usdSurcharge = 1.15;

interface Props {
  project: Project;
  lts: Lts;
  paymentPlans: any;
}

const { TextArea } = Input;

const Summary: React.FunctionComponent<Props> = (props) => {
  const [paymentPlan, setPaymentPlan] = useState<string>(props.project.paymentPlan!);
  const [generatingPdf, setGeneratingPdf] = useState(false);
  const [finalPrice, setFinalPrice] = useState<number>(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [pdfLang, setPdfLang] = useState<Locale>(
    props.project.currency === Currency.Krona ? Locale.Swedish : Locale.English
  );

  const { t } = useTranslation();

  useEffect(() => {
    document.getElementById("totalPrice")!.innerHTML = Math.round(
      calculateInstallment(getFinalPrice(), paymentPlan)
    ).toLocaleString("sv-SE");
    props.project.paymentPlan = paymentPlan;
  }, [paymentPlan, finalPrice]);

  const getLtsProductsById = (id: string): ProjectProduct => {
    const product = props.lts.products.find((product) => product.id === id);
    return JSON.parse(JSON.stringify(product!)); // TODO: Catch error if product is undefined
  };

  // NOTE: Changes to this logic must be reflected in the backend as well (PimService.GetLTSProducts)
  const getLtsProducts = () => {
    if (!props.project.lts || props.project.ccu) return [];

    let vccFound: boolean = false;

    for (const product of props.project.products) {
      if (product.amount > 0 && props.lts.vccIds.findIndex((id) => id === product.id) > -1) {
        vccFound = true;
        break;
      }
    }

    let multiXLFound: boolean = false;

    for (const product of props.project.products) {
      if (product.amount > 0 && props.lts.multiXLIds.findIndex((id) => id === product.id) > -1) {
        multiXLFound = true;
        break;
      }
    }

    if (vccFound) {
      return getVCCLTSProducts(multiXLFound);
    } else {
      return getNonVCCLTSProducts(multiXLFound);
    }
  };

  const getVCCLTSProducts = (multiXL: boolean) => {
    if (!props.project.lts) return [];
    const products = [];

    if (props.project.numberOfChemicals > 0) {
      const product = getLtsProductsById("8550");
      product.amount = props.project.numberOfChemicals;
      products.push(product);
    }

    let vccCount = 0;
    props.project.products.forEach((product) => {
      if (product.family === "central_chemicals_unit" && product.amount > 0) {
        vccCount += product.amount;
      }
    });

    const ltsProduct = getLtsProductsById("8520");
    ltsProduct.amount = vccCount;
    products.push(ltsProduct);

    let waterPumpCount = 0;
    props.project.products.forEach((product) => {
      if (product.family === "central_water_unit" && product.amount > 0) {
        waterPumpCount += product.amount;
      }
    });

    if (multiXL) {
      const ltsProduct = getLtsProductsById("8526");
      ltsProduct.amount = waterPumpCount;
      products.push(ltsProduct);
    } else {
      const ltsProduct = getLtsProductsById("8525");
      ltsProduct.amount = waterPumpCount;
      products.push(ltsProduct);
    }

    return products;
  };

  const getNonVCCLTSProducts = (multiXL: boolean) => {
    if (!props.project.lts) return [];
    const products = [];

    if (multiXL) {
      const ltsProduct = getLtsProductsById("8531");
      ltsProduct.amount = 1;
      products.push(ltsProduct);
    } else {
      let multiFound: boolean = false;

      for (const product of props.project.products) {
        if (product.amount > 0 && props.lts.multiIds.findIndex((id) => id === product.id) > -1) {
          multiFound = true;
          break;
        }
      }

      if (multiFound) {
        const ltsProduct = getLtsProductsById("8530");
        ltsProduct.amount = 1;
        products.push(ltsProduct);
      } else {
        const ltsProduct = getLtsProductsById("8528");
        ltsProduct.amount = 1;
        products.push(ltsProduct);
      }
    }

    return products;
  };

  const getProducts = () => {
    const products = props.project.products.filter((product) => product.amount > 0);
    props.lts.products.forEach((product) => {
      products.push(product);
    });

    getLtsProducts().forEach((product) => {
      products.push(product);
    });

    return products;
  };

  const convertPrice = (price: number) => {
    switch (props.project.currency) {
      case Currency.Euro:
        return price / SekPerEuro;

      case Currency.Dollar:
        return (price / SekPerDollar) * usdSurcharge;
    }

    return price;
  };

  const getLicensCost = (currency: Currency) => {
    switch (currency) {
      case Currency.Euro:
        return "100€";

      case Currency.Dollar:
        return "$100";

      case Currency.Krona:
        return "1000 SEK";
    }
  };

  // Returns the total material cost for the project
  // NOTE: Changes to this logic must be reflected in the backend as well (internal/domain/project/aggregate/project.GetTotalPrice)
  const getTotalPrice = () => {
    let totalPrice = 0;

    for (const product of props.project.products) {
      totalPrice += product.price * product.amount;
    }

    for (const lts of getLtsProducts()) {
      totalPrice += lts.price * lts.amount;
    }

    let totalProductsCost = totalPrice;

    if (props.project.installation) {
      if (props.project.dosing === "central") {
        totalPrice += totalProductsCost * props.project.installationCostPercentage;
      } else {
        totalPrice += totalProductsCost * props.project.installationCostPercentage;
      }
    }

    if (props.project.pipes) {
      if (props.project.dosing === "central") {
        totalPrice += totalProductsCost * props.project.pipesCostPercentage;
      } else {
        totalPrice += totalProductsCost * props.project.pipesCostPercentage;
      }
    }

    if (props.project.serviceAgreement36Months) {
      totalPrice += totalProductsCost * props.project.serviceCostPercentage;
    }

    return convertPrice(totalPrice);
  };

  const getFinalPrice = () => {
    return fixedPrice >= 0 ? fixedPrice : getTotalPrice();
  };

  const [fixedPrice, setFixedPrice] = useState<number>(props.project.fixedPrice);
  const [inputValue, setInputValue] = useState<number | null>(Math.round(getFinalPrice()));
  const [showPriceFields, setShowPriceFields] = useState<boolean>(false);

  // NOTE: Changes to this logic must be reflected in the backend as well (internal/domain/project/aggregate/project.GetTotalPrice)
  const calculateInstallment = (totalPrice: number, months: string) => {
    return (totalPrice / props.paymentPlans[months].months) * (1 + props.paymentPlans[months].costPercentage);
  };

  useEffect(() => {
    setFinalPrice(fixedPrice >= 0 ? fixedPrice : getTotalPrice());
  }, [fixedPrice]);

  const handleChange = (value: string) => {
    setPaymentPlan(value);
  };

  const handleInputChange = (value: number | null) => {
    setInputValue(value);
  };

  const handleSubmit = () => {
    setFixedPrice(inputValue ?? -1);
    setFinalPrice(inputValue ?? getTotalPrice());
    togglePriceFields();
    props.project.fixedPrice = inputValue ?? -1;
  };

  const togglePriceFields = () => {
    document.getElementById("totalPriceInputField")!.style.display === "none"
      ? (document.getElementById("totalPriceInputField")!.style.display = "block")
      : (document.getElementById("totalPriceInputField")!.style.display = "none");
    document.getElementById("priceField")!.style.display === "none"
      ? (document.getElementById("priceField")!.style.display = "block")
      : (document.getElementById("priceField")!.style.display = "none");
  };

  const togglePrices = (checked: boolean) => {
    setShowPriceFields(checked);
  };

  const onFinish = (values: EmailContent) => {
    ProjectsService.sendEmail(props.project, pdfLang, values);
    setModalOpen(false);
  };

  return (
    <div className="summary">
      <List
        bordered
        footer={
          <div
            className="footer"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Select
              // labelInValue
              defaultValue={paymentPlan}
              style={{ width: 120 }}
              onChange={handleChange}
              options={[
                {
                  value: "onetime",
                  label: "Total price",
                },
                {
                  value: "24months",
                  label: "24 Months",
                },
                {
                  value: "36months",
                  label: "36 Months",
                },
                {
                  value: "60months",
                  label: "60 Months",
                },
              ]}
            />
            <div
              id="priceField"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span id="totalPrice">{Math.round(finalPrice).toLocaleString("sv-SE")} </span>
                <span> {" "}</span> {/* Non Breaking Space */}
                <span>{props.project.currency}</span>
              </div>
              {paymentPlan !== "onetime" && (
                <Alert message={t("summaryPage.interestCharge")} type="warning" showIcon style={{ marginTop: 16 }} />
              )}
            </div>
            <div id="totalPriceInputField" style={{ display: "none" }}>
              <Input.Group compact>
                <InputNumber
                  addonAfter={props.project.currency}
                  min={0}
                  precision={0}
                  placeholder={Math.round(getTotalPrice()).toLocaleString("sv-SE")}
                  style={{ width: "calc(100% - 110px)" }}
                  value={inputValue}
                  onChange={handleInputChange}
                />
                <Button type="primary" onClick={handleSubmit}>
                  <CheckOutlined />
                </Button>
              </Input.Group>
            </div>
          </div>
        }
      >
        {getProducts().map(
          (product) =>
            product.amount > 0 && (
              <List.Item key={product.id}>
                <div>
                  {product.id} - {product.name}
                </div>
                <div>
                  {showPriceFields ? (
                    <span className="product-price">
                      {Math.round(convertPrice(product.price)).toLocaleString("sv-SE")} -{" "}
                      {Math.round(convertPrice(product.price * product.amount)).toLocaleString("sv-SE")}{" "}
                      {props.project.currency} -{" "}
                    </span>
                  ) : null}{" "}
                  {product.amount} st
                </div>
              </List.Item>
            )
        )}

        {props.project.lts && (
          <List.Item>
            <div>
              <div>
                <h3>LTS</h3>
              </div>
              <div>
                Hardware is mounted into the central units and delivered key ready. Permanent internet access is needed
                (customer supplies) and you can then log into the Lagafors platform for logging, documentation and
                traceability of the cleaning process. In addition to this, a license cost per quarter of 100 € is added
                per media.
              </div>
            </div>
          </List.Item>
        )}
        {props.project.pipes && (
          <List.Item>
            <div>
              <div>
                <h3>Pipes</h3>
              </div>
              <div>
                All pipeline material between the central units and the satellite stations is included. For water
                completely in stainless material. For chemicals and air, chemical resistant plastic pipes is used if
                nothing else is agreed.
              </div>
            </div>
          </List.Item>
        )}
        {props.project.installation && (
          <List.Item>
            <div>
              <div>
                <h3>Installation</h3>
              </div>
              <div>
                Equipment and pipe lines installed on site. The complete system is handed over key ready to the
                customer. Technical and operator training is included.
              </div>
            </div>
          </List.Item>
        )}
        {props.project.serviceAgreement36Months && (
          <List.Item>
            <div>
              <div>
                <h3>Service Agreement</h3>
              </div>
              <div>
                Technicians from Lagafors visit the facility once a year to maintain function and accessibility to the
                cleaning system. Travel and labour costs are included as well as spare parts. The scope includes central
                units and satellite stations (hoses, nozzles, etc. is not included).
              </div>
            </div>
          </List.Item>
        )}
      </List>
      {props.project.lts && (
        <p className="disclaimer">
          {t("summaryPage.licensCost", {
            licenseCost: getLicensCost(props.project.currency),
          })}
        </p>
      )}

      <div className="button-container">
        <div>
          <span>Show prices </span>
          <Switch className="price-switch" onChange={togglePrices} />
        </div>
        <Button
          icon={<SettingOutlined />}
          className="change-button"
          onClick={() => {
            togglePriceFields();
          }}
        />
      </div>

      {props.project.roiData && (
        <CsaSummary decentralDosing={false} {...props.project.roiData} currency={props.project.currency} hideFooter />
      )}

      <div className="button-container">
        <Button
          icon={<FilePdfOutlined />}
          className="pdf-button"
          disabled={generatingPdf}
          onClick={() => setModalOpen(true)}
        >
          PDF
        </Button>

        <Modal
          title="Create PDF"
          centered
          open={modalOpen}
          onOk={() => setModalOpen(false)}
          onCancel={() => setModalOpen(false)}
          footer={null}
        >
          <div className="pdf-modal">
            {/* <p>Here you can download the generated offer in different languages.</p> */}
            {/* <p>Here you can download the generated offer or you can send it to the customer as a PDF.</p> */}
            <h3>Select language</h3>
            <Radio.Group
              value={pdfLang}
              onChange={(newLang) => setPdfLang(newLang.target.value)}
              optionType="button"
              buttonStyle="solid"
              options={[
                { label: "Svenska", value: Locale.Swedish },
                { label: "English", value: Locale.English },
                { label: "Deutsch", value: Locale.Deutsch },
                { label: "Español", value: Locale.Español },
              ]}
            />
            <h3>Download PDF</h3>
            <Button
              icon={<FilePdfOutlined />}
              className="pdf-button"
              disabled={generatingPdf}
              loading={generatingPdf ? { delay: 0 } : false}
              onClick={async () => {
                try {
                  setGeneratingPdf(true);

                  let blob = await ProjectsService.getPdf(props.project, pdfLang);
                  blob = new Blob([blob], { type: "application/pdf" }); // Recreating the blob and explicitly setting MIME-type apparently improves compatibility with some browsers

                  const objectUrl = URL.createObjectURL(blob);
                  const link = document.createElement("a");

                  // Emulate a clicked link to allow the file to be downloaded
                  link.href = objectUrl;
                  link.target = "_blank";
                  link.download = props.project.id + ".pdf";
                  link.click();

                  // For Firefox it is necessary to delay revoking the ObjectURL
                  setTimeout(function () {
                    URL.revokeObjectURL(objectUrl);
                  }, 100);
                } catch (error) {
                  console.warn("error fetching project PDF", error);
                } finally {
                  setGeneratingPdf(false);
                }
              }}
            >
              Download
            </Button>
            <Button
              icon={<FilePdfOutlined />}
              className="pdf-button"
              disabled={generatingPdf}
              loading={generatingPdf ? { delay: 0 } : false}
              onClick={async () => {
                try {
                  setGeneratingPdf(true);

                  let blob = await ProjectsService.getPdf(props.project, pdfLang);
                  blob = new Blob([blob], { type: "application/pdf" });

                  const objectUrl = URL.createObjectURL(blob);

                  window.open(objectUrl, "_blank");

                  // Delay revoking the ObjectURL for Firefox compatibility
                  setTimeout(function () {
                    URL.revokeObjectURL(objectUrl);
                  }, 100);
                } catch (error) {
                  console.warn("error fetching project PDF", error);
                } finally {
                  setGeneratingPdf(false);
                }
              }}
            >
              Open
            </Button>

            <h3 id="modal-header">Send PDF</h3>
            <Form onFinish={onFinish}>
              <Form.Item
                name="address"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                    max: 255,
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                    max: 100,
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item
                name="subject"
                rules={[
                  {
                    required: true,
                    message: "Please input your subject!",
                    max: 1000,
                  },
                ]}
              >
                <Input placeholder="Subject" />
              </Form.Item>
              <Form.Item
                name="text"
                rules={[
                  {
                    required: true,
                    message: "Please input your message!",
                  },
                ]}
              >
                <TextArea rows={4} placeholder="Message" />
              </Form.Item>
              <Form.Item>
                <div style={{ justifyContent: "end", display: "flex" }}>
                  <Button className="send-email-button" type="primary" htmlType="submit">
                    Send
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Summary;
